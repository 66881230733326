import React, { useEffect } from 'react';

import Header from './components/header';
import Footer from './components/footer';

function App() {
    useEffect(() => {
        document.title = "Work — Pirabaa";
    }, []);

    return (
        <>
            <Header />
            <main>
                <section id='hero' className="hero page-hero p-0 pt-2">
                    <div className="hero-text align-content-center">
                        <div className='container'>
                            <div className='row flex-lg-row flex-column gap-4'>
                                <div className='col-auto'>
                                    <h1 className='display-4'>My Work</h1>
                                    <p className='my-0'>
                                        Check out my latest projects, where web development, design, and technology come together to create seamless, visually stunning solutions. Each project is built with a commitment to quality, enhancing user experiences while delivering real results.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="work" className="work pt-0">
                    <div className="container">
                        <div className='row flex-lg-row flex-column gap-3 gap-lg-0'>
                            <div className='col'>
                                <div className="project-card position-relative overflow-hidden w-100">
                                    <div class="project-image" style={{ backgroundImage: 'url("https://images.prismic.io/pirabaa/ZzQuy68jQArT0yU2_portfolio.png?auto=format,compress")' }}></div>
                                    <div className='content'>
                                        <div>
                                            <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 project-type'>Featured Project</h3>
                                            <h3 className='m-0 fw-bold'>pirabaa.ca</h3>
                                        </div>
                                        <a href='/'>
                                            <i class="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="project-card position-relative overflow-hidden w-100">
                                    <div class="project-image" style={{ backgroundImage: 'url("https://images.prismic.io/pirabaa/ZzVPE68jQArT02su_malarify.png?auto=format,compress")' }}></div>
                                    <div className='content'>
                                        <div>
                                            <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 project-type'>Featured Project</h3>
                                            <h3 className='m-0 fw-bold'>Malarify</h3>
                                        </div>
                                        <a href='https://malarify.vercel.app/'>
                                            <i class="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="project-card position-relative overflow-hidden w-100">
                                    <div class="project-image" style={{ backgroundImage: 'url("https://images.prismic.io/pirabaa/ZzVPXq8jQArT02sx_cinemax.png?auto=format,compress")' }}></div>
                                    <div className='content'>
                                        <div>
                                            <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 project-type'>Featured Project</h3>
                                            <h3 className='m-0 fw-bold'>Cinemax</h3>
                                        </div>
                                        <a href='https://cinemax-pi.vercel.app/'>
                                            <i class="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default App;
import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

import Header from './components/header';
import Footer from './components/footer';

function App() {
    const [hasAnnouncement, setHasAnnouncement] = useState(false);
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        document.title = "Contact — Pirabaa";
    }, []);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_vgd48ve', 'template_janhwhd', form.current, {
                publicKey: 'pPDKcsQskg1u39a3t',
            })
            .then(
                () => {
                    setHasAnnouncement(true);
                    setHasError(false);
                    setTimeout(() => setHasAnnouncement(false), 5000);
                },
                (error) => {
                    setHasError(true);
                    setHasAnnouncement(false);
                    setTimeout(() => setHasError(false), 5000);
                }
            );
    };

    console.log('EmailJS Service ID:', process.env.REACT_APP_EMAILJS_SERVICE_ID);
    console.log('EmailJS Template ID:', process.env.REACT_APP_EMAILJS_TEMPLATE_ID);
    console.log('EmailJS Public Key:', process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

    return (
        <>
            {(hasAnnouncement || hasError) && (
                <div
                    className={`position-fixed top-0 start-0 w-100 text-center text-white fw-lighter notification-top-bar  ${hasError ? 'bg-danger' : 'bg-success'
                        }`}
                >
                    <p className="p-0 m-0">
                        {hasError
                            ? 'There was an error sending your message. Please try again later.'
                            : 'Your message has been sent successfully. Thank you for reaching out!'}
                    </p>
                </div>
            )}
            <Header hasAnnouncement={hasAnnouncement} />
            <main>
                <section id="hero" className="hero page-hero p-0 pt-2">
                    <div className="hero-text align-content-center">
                        <div className='container'>
                            <div className='row flex-lg-row flex-column gap-4'>
                                <div className='col-auto'>
                                    <h1 className='display-4'>Get in Touch</h1>
                                    <p className="my-0">
                                        Get in touch! Whether you need advice, have a project in mind, or just want to connect, I’m here to help bring your ideas to life with innovative web design, development solutions, and creative collaboration.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="contact" className="contact pt-0">
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="container">
                            <div className="row flex-lg-row flex-column gap-3 gap-lg-0">
                                <div className="row flex-lg-row flex-column pe-0">
                                    <div className="col pe-0">
                                        <div className="mb-3">
                                            <label htmlFor="first_name" className="form-label">First Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="first_name" name="first_name" required />
                                        </div>
                                    </div>
                                    <div className="col pe-0">
                                        <div className="mb-3">
                                            <label htmlFor="last_name" className="form-label">Last Name<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control" id="last_name" name="last_name" required />
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email address<span className="text-danger">*</span></label>
                                    <input type="email" className="form-control" id="email" name="email" required />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="message" className="form-label">Message<span className="text-danger">*</span></label>
                                    <textarea className="form-control" id="message" name="message" rows="5" required></textarea>
                                </div>
                                <div className="mt-2">
                                    <input className="border-0 text-white contact-submit m-auto" type="submit" value="Send Message" />
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default App;
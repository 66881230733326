import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Header = ({ hasAnnouncement }) => {
    const location = useLocation();
    const [isMobileNavVisible, setIsMobileNavVisible] = useState(false);
    const [isScrolled, setIsScrolled] = useState(window.scrollY > 0);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 80);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div>
            <div className={`bg-opac w-100 vh-100 d-lg-none position-fixed top-0 left-0 z-3 ${isMobileNavVisible ? 'opacity-1' : 'opacity-0'}`}></div>
            <header
                className="header w-100"
                id="header"
                style={{
                    padding: isScrolled ? '15px' : '0',
                    top: isScrolled ? '0' : hasAnnouncement ? '75px' : '35px'
                }}
            >
                <div className="container d-flex justify-content-between">
                    <Link to="/" className="navbar-brand">
                        PiRaBaa
                    </Link>
                    <nav id="navbar" className="navbar order-last p-0" style={{ float: "right" }}>
                        <ul className='m-0 p-0 d-flex list-unstyled align-items-center d-none d-lg-flex'>
                            <li>
                                <Link to="/" className={`d-flex align-items-center justify-content-between ${location.pathname === '/' ? 'active' : ''}`}>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link to="/work" className={`d-flex align-items-center justify-content-between ${location.pathname === '/work' ? 'active' : ''}`}>
                                    Work
                                </Link>
                            </li>
                            <li>
                                <Link to="/blog" className={`d-flex align-items-center justify-content-between ${location.pathname === '/blog' ? 'active' : ''}`}>
                                    Blog
                                </Link>
                            </li>
                            <li>
                                <Link to="/contact" className='d-flex align-items-center justify-content-between border-0 text-white cta-button'>
                                    Get in Touch
                                </Link>
                            </li>
                        </ul>
                        <i className={`bi mobile-nav-toggle d-lg-none d-block ${isMobileNavVisible ? 'bi-x' : 'bi-list'}`} onClick={() => setIsMobileNavVisible(!isMobileNavVisible)} />
                    </nav>
                    <nav id="mobile-nav" className={`mobile-nav position-fixed bg-white w-75 text-center d-lg-none d-flex flex-column justify-content-between ${isMobileNavVisible ? 'mobile-nav-show' : ''}`}>
                        <ul className='p-0 list-unstyled mb-0 pe-4 pt-4'>
                            <li className='d-lg-none d-block'>
                                <Link to="/" className={`d-flex align-items-end justify-content-end text-decoration-none ${location.pathname === '/' ? 'active' : ''}`}>
                                    Home
                                </Link>
                            </li>
                            <li className='d-lg-none d-block'>
                                <Link to="/work" className={`d-flex align-items-end justify-content-end text-decoration-none ${location.pathname === '/work' ? 'active' : ''}`}>
                                    Work
                                </Link>
                            </li>
                            <li className='d-lg-none d-block'>
                                <Link to="/blog" className={`d-flex align-items-end justify-content-end text-decoration-none ${location.pathname === '/blog' ? 'active' : ''}`}>
                                    Blog
                                </Link>
                            </li>
                        </ul>
                        <div>
                            <div className='seperator'></div>
                            <Link to="/contact" className='d-flex align-items-center justify-content-between border-0 bg-white cta-button mb-4 me-4 float-end'>
                                <span className='me-2'>Get in Touch</span><i className="bi bi-arrow-right-short"></i>
                            </Link>
                        </div>
                    </nav>
                </div>
            </header>
        </div>
    );
};

export default Header;
import React from 'react';

const Footer = () => {
  return (
    <footer className='footer pb-5' id='footer'>
      <div className="container px-lg-0 px-4">
        <div className="row gap-lg-5 gap-3 align-items-center flex-column-reverse flex-lg-row text-center text-lg-start">
          <div className="col">
            <span style={{ fontSize: 14 }}>
            © {new Date().getFullYear()} Pirabaa Segar. All Rights Reserved.
            </span>
          </div>
          <div className="col-auto">
            <ul className='list-unstyled p-0 d-flex gap-4 mb-0 justify-content-end'>
              <li className='mb-0'>
                <a href="https://www.linkedin.com/in/pirabaasegar/">
                  <i className="bi bi-linkedin" />
                </a>
              </li>
              <li className='mb-0'>
                <a href="https://github.com/pirabaasegar/">
                  <i className="bi bi-github" />
                </a>
              </li>
              <li className='mb-0'>
                <a href="https://medium.com/@pirabaasegar">
                  <i className="bi bi-medium" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useEffect } from 'react';

import Header from './components/header';
import Footer from './components/footer';

function App() {
  useEffect(() => {
    document.title = "Pirabaa — Frontend Developer";
  }, []);
  return (
    <>
      <Header />
      <main>
        <section id='hero' className="hero p-0 pt-2">
          <div className="hero-text align-content-center">
            <div className='container'>
              <div className='row flex-lg-row flex-column gap-4'>
                <div className='col-lg-7 col'>
                  <h1 className='display-4'>Hi, I'm Pirabaa<br></br>a frontend developer,<br></br>designer and student</h1>
                  <p className='my-0'>
                    Based in Toronto, I work as a web designer, developer, and student.
                  </p>
                </div>
                <div className='col d-flex align-items-center justify-content-center d-lg-none'>
                  <img src='https://images.prismic.io/pirabaa/ZzN2Aa8jQArT0xT9_personal.jpeg?auto=format,compress' className='rounded-3 img-fluid' alt='personal'></img>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="about" className="about section-bg">
          <div className="container">
            <div className="row justify-content-center">
              <h2 className="text-start fw-semibold position-relative fw-medium mb-2">A little bit about me</h2>
              <p className="mb-0 lh-lg">
                Based in Toronto, I work as a web designer, developer, and student. I've worked with companies for a number of years, developing powerful, useful brand identities and digital experiences that convert well. I take great satisfaction in using top-notch software and writing clear, reliable code to create and develop faultless websites, designs, and brand identities. My work is visually stunning without sacrificing performance. Who doesn't love a website that is both aesthetically pleasing and incredibly functional?
              </p>
            </div>
          </div>
        </section>
        <section id="work" className="work">
          <div className="section-title pb-0">
            <div className='container'>
              <div className='row'>
                <div className='col-auto'>
                  <h2 className='fw-bold position-relative m-0'>Work</h2>
                  <p>Explore my projects, highlighting impactful web design and development solutions</p>
                </div>
                <div className='col d-flex justify-content-start justify-content-lg-end align-items-end'>
                  <a href="/work" className='d-flex align-items-center justify-content-between border-0 bg-white see-more mb-3 float-end'>
                    <span className='me-2 text-secondary'>See More</span><i class="bi bi-arrow-right-short"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className='row flex-lg-row flex-column gap-3 gap-lg-0'>
              <div className='col'>
                <div className="project-card position-relative overflow-hidden w-100">
                  <div class="project-image" style={{ backgroundImage: 'url("https://images.prismic.io/pirabaa/ZzQuy68jQArT0yU2_portfolio.png?auto=format,compress")' }}></div>
                  <div className='content'>
                    <div>
                      <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 project-type'>Featured Project</h3>
                      <h3 className='m-0 fw-bold'>pirabaa.ca</h3>
                    </div>
                    <a href='/'>
                      <i class="bi bi-arrow-down-right-circle"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className="project-card position-relative overflow-hidden w-100">
                  <div class="project-image" style={{ backgroundImage: 'url("https://images.prismic.io/pirabaa/ZzVPE68jQArT02su_malarify.png?auto=format,compress")' }}></div>
                  <div className='content'>
                    <div>
                      <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 project-type'>Featured Project</h3>
                      <h3 className='m-0 fw-bold'>Malarify</h3>
                    </div>
                    <a href='https://malarify.vercel.app/'>
                      <i class="bi bi-arrow-down-right-circle"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className="project-card position-relative overflow-hidden w-100">
                  <div class="project-image" style={{ backgroundImage: 'url("https://images.prismic.io/pirabaa/ZzVPXq8jQArT02sx_cinemax.png?auto=format,compress")' }}></div>
                  <div className='content'>
                    <div>
                      <h3 className='m-0 fw-bold fw-bold text-uppercase m-0 project-type'>Featured Project</h3>
                      <h3 className='m-0 fw-bold'>Cinemax</h3>
                    </div>
                    <a href='https://cinemax-pi.vercel.app/'>
                      <i class="bi bi-arrow-down-right-circle"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="tools" className="tools pt-0">
        <div className="section-title pb-0">
            <div className='container'>
              <div className='row'>
                <div className='col-auto'>
                  <h2 className='fw-bold position-relative m-0'>Tools</h2>
                  <p>Discover the tools I use to create seamless and innovative web experiences</p>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className='row flex-column flex-lg-row gap-3 gap-lg-0'>
              <div className='col'>
                <div className='row flex-column gap-3'>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzLLKK8jQArT0t6C_vscode.png?auto=format,compress' width={30} alt='vs-code'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Visual Studio Code</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Efficient, customizable editor</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzLLFa8jQArT0t5__notion.png?auto=format,compress' width={30} alt='notion'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Notion</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Organized team collaboration</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzLNU68jQArT0t6Q_figma.png?auto=format,compress' height={30} alt='figma'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Figma</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Collaborative design tool</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='row flex-column gap-3'>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzQUNK8jQArT0yQ6_adobe.png?auto=format,compress' width={30} alt='acd'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Adobe Creative Cloud</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Powerful design software suite</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzQUka8jQArT0yQ__microsoft365.png?auto=format,compress' width={30} alt='microsft365'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Microsoft 365</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Productivity and collaboration suite</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzQWba8jQArT0yR0_github.png?auto=format,compress' height={30} alt='github'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Github</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Version control and collaboration</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className='row flex-column gap-3'>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzQWpK8jQArT0ySZ_vercel.png?auto=format,compress' height={30} alt='vercel'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Vercel</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Responsive front-end framework</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzQV-q8jQArT0yRm_ga.png?auto=format,compress' width={30} alt='ga'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Google Analytics</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Website traffic tracking tool</h6>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className="tool-card position-relative overflow-hidden w-100 d-flex flex-row gap-3 align-items-center">
                      <div className='icon-container'>
                        <img src='https://images.prismic.io/pirabaa/ZzQXR68jQArT0ySc_dribble.png?auto=format,compress' width={30} alt='dribble'></img>
                      </div>
                      <div className='content'>
                        <h5 className='m-0 tool-name'>Dribble</h5>
                        <h6 className='m-0 text-secondary fw-normal'>Inspiration from the community</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="blog" className="blog pt-0">
          <div className="section-title pb-0">
            <div className='container'>
              <div className='row'>
                <div className='col-auto'>
                  <h2 className='fw-bold position-relative m-0'>Blogs</h2>
                  <p>Read my latest blogs on web design, development, and industry insights</p>
                </div>
                <div className='col d-flex justify-content-start justify-content-lg-end align-items-end'>
                  <a href="/blog" className='d-flex align-items-center justify-content-between border-0 bg-white see-more mb-3 float-end'>
                    <span className='me-2 text-secondary'>See More</span><i class="bi bi-arrow-right-short"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className='row flex-lg-row flex-column gap-3 gap-lg-0'>
              <div className='col'>
                <div className="project-card position-relative overflow-hidden w-100">
                  <div class="project-image" style={{ backgroundImage: 'url("https://miro.medium.com/v2/resize:fit:720/format:webp/1*AH4vsnt-4yrQaLWoZsHgXw.png")' }}></div>
                  <div className='content'>
                    <div>
                      <div class="blog-info">
                        <span>AI</span>
                        <span>Nov 4, 2023</span>
                      </div>
                      <h3 className='m-0 fw-bold'>Unveiling the Mysteries of the Human Body:<br></br>A Journey of Discovery with AI</h3>
                    </div>
                    <a href='https://medium.com/@pirabaasegar/unveiling-the-mysteries-of-the-human-body-a-journey-of-discovery-with-artificial-intelligence-df8fef832ed1'>
                      <i class="bi bi-arrow-down-right-circle"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className='col'>
                <div className="project-card position-relative overflow-hidden w-100">
                  <div class="project-image" style={{ backgroundImage: 'url("https://miro.medium.com/v2/resize:fit:720/format:webp/1*ByKzzvxnkvQ-qUsAksCR3w.jpeg")' }}></div>
                  <div className='content'>
                    <div>
                      <div class="blog-info">
                        <span>AI</span>
                        <span>Nov 4, 2023</span>
                      </div>
                      <h3 className='m-0 fw-bold'>Revealing Language Barriers: An Exploration of Translation with Code</h3>
                    </div>
                    <a href='https://medium.com/@pirabaasegar/revealing-language-barriers-an-exploration-of-translation-with-code-b4806bb80cff'>
                      <i class="bi bi-arrow-down-right-circle"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default App;

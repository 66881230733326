import React, { useEffect } from 'react';

import Header from './components/header';
import Footer from './components/footer';

function App() {
    useEffect(() => {
        document.title = "pirabaa.ca";
    }, []);

    return (
        <>
            <Header />
            <main>
                <section id='hero' className="hero error-page-hero page-hero p-0">
                    <div className="hero-text align-content-center">
                        <div className='container'>
                            <div className='row flex-lg-row flex-column gap-4 justify-content-center'>
                                <div className='col-auto text-center'>
                                    <h1 className='display-1 mb-0'>404</h1>
                                    <h3 className='fw-bold'>Oops, page not found!</h3>
                                    <p>
                                        The page you are looking for does not exist or has been moved.
                                    </p>
                                    <a href="/" className='d-flex align-items-center justify-content-between border-0 text-white go-home-btn m-auto' >
                                        Go to Homepage
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default App;
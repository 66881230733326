import React, { useEffect } from 'react';

import Header from './components/header';
import Footer from './components/footer';

function App() {
    useEffect(() => {
        document.title = "Blog — Pirabaa";
    }, []);

    return (
        <>
            <Header />
            <main>
                <section id='hero' className="hero page-hero p-0 pt-2">
                    <div className="hero-text align-content-center">
                        <div className='container'>
                            <div className='row flex-lg-row flex-column gap-4'>
                                <div className='col-auto'>
                                    <h1 className='display-4'>Blog</h1>
                                    <p className='my-0'>
                                        Welcome to my blog, where I share insights on web design, development, and tech trends. I explore new tools, frameworks, and creative solutions to inspire and keep you ahead in the ever-evolving digital landscape.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="blog" className="blog pt-0">
                    <div className="container">
                        <div className='row flex-lg-row flex-column gap-3 gap-lg-0'>
                            <div className='col'>
                                <div className="project-card position-relative overflow-hidden w-100">
                                    <div class="project-image" style={{ backgroundImage: 'url("https://miro.medium.com/v2/resize:fit:720/format:webp/1*AH4vsnt-4yrQaLWoZsHgXw.png")' }}></div>
                                    <div className='content'>
                                        <div>
                                            <div class="blog-info">
                                                <span>AI</span>
                                                <span>Nov 4, 2023</span>
                                            </div>
                                            <h3 className='m-0 fw-bold'>Unveiling the Mysteries of the Human Body:<br></br>A Journey of Discovery with AI</h3>
                                        </div>
                                        <a href='https://medium.com/@pirabaasegar/unveiling-the-mysteries-of-the-human-body-a-journey-of-discovery-with-artificial-intelligence-df8fef832ed1'>
                                            <i class="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='col'>
                                <div className="project-card position-relative overflow-hidden w-100">
                                    <div class="project-image" style={{ backgroundImage: 'url("https://miro.medium.com/v2/resize:fit:720/format:webp/1*ByKzzvxnkvQ-qUsAksCR3w.jpeg")' }}></div>
                                    <div className='content'>
                                        <div>
                                            <div class="blog-info">
                                                <span>AI</span>
                                                <span>Nov 4, 2023</span>
                                            </div>
                                            <h3 className='m-0 fw-bold'>Revealing Language Barriers: An Exploration of Translation with Code</h3>
                                        </div>
                                        <a href='https://medium.com/@pirabaasegar/revealing-language-barriers-an-exploration-of-translation-with-code-b4806bb80cff'>
                                            <i class="bi bi-arrow-down-right-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export default App;